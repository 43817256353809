function getWidth(width) {
    let data = {};

    if (width < '576') {
        data = {
            bs4: 'xs',
            color: '#f39c12'
        };
    }
    if (width >= '576') {
        data = {
            bs4: 'sm',
            color: '#d35400'
        };
    }
    if (width >= '768') {
        data = {
            bs4: 'md',
            color: '#c0392b'
        };
    }
    if (width >= '992') {
        data = {
            bs4: 'lg',
            color: '#7f8c8d'
        };
    }
    if (width >= '1200') {
        data = {
            bs4: 'xl',
            color: '#2c3e50'
        };
    }
    if (width >= '1600') {
        data = {
            bs4: 'xxl',
            color: '#333333'
        };
    }

    return data;
}

function setViewportSizeOnResize() {
    const height = window.innerHeight;
    const width = window.innerWidth;
    const args = getWidth(width);

    const viewportbox = document.getElementById('viewportsize');
    viewportbox.style.backgroundColor = args.color;
    viewportbox.innerHTML = '<strong>' + width + '</strong>px / <strong>' + height + '</strong>px | <strong>' + args.bs4 + '</strong>';
}

export function showViewPortSize(display) {
    if (display) {
        const height = window.innerHeight;
        const width = window.innerWidth;
        const args = getWidth(width);

        const viewportSize = document.createElement('div');
        viewportSize.id = 'viewportsize';
        viewportSize.style.cssText = 'font-weight: normal;font-size: 12px;z-index:9999;position:fixed;bottom:0px;left:0px;color:#fff;background-color: ' + args.color + ';padding:10px';
        viewportSize.innerHTML = '<strong>' + width + '</strong>px / <strong>' + height + '</strong>px | <strong>' + args.bs4 + '</strong>';

        document.body.appendChild(viewportSize);

        window.onresize = setViewportSizeOnResize;
    }
}
