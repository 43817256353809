// determines if the user has a set theme
export function detectColorScheme() {
    // default to light
    let theme = 'light';

    // local storage is used to override OS theme settings
    if (window.localStorage.getItem('theme')) {
        if (window.localStorage.getItem('theme') === 'dark') {
            theme = 'dark';
        }
    } else if (!window.matchMedia) {
        // matchMedia method not supported
        return false;
    } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        // OS theme setting detected as dark
        theme = 'dark';
    }

    // dark theme preferred, set document with a `data-theme` attribute
    if (theme === 'dark') {
        document.documentElement.setAttribute('data-theme', 'dark');
    }
}
