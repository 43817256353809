import { smoothscroll } from './smoothscroll';
import { showViewPortSize } from './showViewportSize';
import { detectColorScheme } from './detectColorScheme';
import { switchTheme } from './toggleColorScheme';

// import demo module
// import fooFighters from './demo-modules/foo-fighters';

/* global alert */
export function testme() {
    alert('hi!');
}

// on document ready
document.addEventListener('DOMContentLoaded', function() {
    // Learn to Fly!
    // console.dir(fooFighters);
    showViewPortSize(false);

    // identify the toggle switch HTML element
    const toggleSwitch = document.querySelector('#theme-switch input[type="checkbox"]');

    // listener for changing themes
    toggleSwitch.addEventListener('change', switchTheme, false);

    // pre-check the dark-theme checkbox if dark-theme is set
    if (document.documentElement.getAttribute('data-theme') === 'dark') {
        toggleSwitch.checked = true;
    }
});

smoothscroll();
detectColorScheme();
